import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, makeStyles, createStyles, Button } from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";
import { getBotVersioned } from "../../../graphql/queries";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
import fetch from "fetch";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyBotPreModal() {
  const { botId } = useParams();
  const classes = useStyles();

  const [bot, setBot] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [preModal, setPreModal] = useState({});
  const [servmanInfo, setServmanInfo] = useState([]);
  const [currentServmanCat, setCurrentServmanCat] = useState("");
  const [currentServmanProblem, setCurrentServmanProblem] = useState("");
  const [addType, setAddType] = useState("message");
  const [reload, setReload] = useState(false);

  const fetchBot = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getBotVersioned, { id: botId, version: "v0" }),
      );
      if (fetch.data.getBotVersioned.preModal) {
        try {
          fetch.data.getBotVersioned.preModal = JSON.parse(
            fetch.data.getBotVersioned.preModal,
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        fetch.data.getBotVersioned.preModal = JSON.parse("[]");
      }
      console.log(fetch.data.getBotVersioned.preModal);
      setBot(fetch.data.getBotVersioned);
      let pdata = fetch.data.getBotVersioned.preModal;

      const localbot = fetch.data.getBotVersioned;

      if (!pdata.length) {
        if (localbot?.company && localbot.company.crmSolution === "servman") {
          pdata = [
            {
              name: "servman-questions",
              type: "servman-questions",
              steps: [],
              probing_questions: [],
            },
          ];
        } else {
          pdata = [
            {
              name: "preModal-questions",
              type: "preModal-questions",
              steps: [],
              probing_questions: [],
            },
          ];
        }
      }

      setPreModal(pdata);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (!reload) return;
    setTimeout(() => {
      setReload(false);
    }, 100);
  }, [reload]);

  useEffect(() => {
    // fetch customer record here
    fetchBot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!bot || !bot.botCompanyId || !bot.id) return;
    const payload = {
      method: "POST",
      payload: JSON.stringify({
        companyId: bot.botCompanyId,
        botId: bot.id,
        crmId: null,
        branchId: "none",
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch.fetchUrl(
      `${process.env.REACT_APP_DASHBOARD_API}/v1/servman/info`,
      payload,
      (err, res, body) => {
        if (err) {
          console.log(err);
          return;
        }
        const info = JSON.parse(body.toString("utf-8"));
        setServmanInfo(info);
        if (info.length) {
          setCurrentServmanCat(info[0]);
          if (info[0].problems.length) {
            setCurrentServmanProblem(info[0].problems[0]);
          }
        }
      },
    );
  }, [bot]);

  const updateBotCb = async () => {
    const payload = { ...bot, preModal: JSON.stringify(preModal, null, 2) };
    // if(payload.input.objections.objections) {
    //     payload.input.objections = payload.input.objections.objections;
    // }
    try {
      await createNewVersion(bot.id, "bot", payload);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>
          {bot.botTitle}{" "}
          {bot && bot.company.crmSolution === "servman"
            ? "Servman Questions"
            : "Pre-Modal Questions"}
        </Title>
      </div>
      <div className={classes.container}>
        <Button
          style={{ marginRight: 10, marginBottom: 20 }}
          onClick={updateBotCb}
          color="primary"
          variant="contained"
        >
          Save{" "}
          {bot && bot.company.crmSolution === "servman"
            ? "Servman"
            : "Pre-Modal"}{" "}
          Questions
        </Button>
        {!reload && (
          <Editor value={preModal} onChange={(e) => setPreModal(e)} />
        )}
      </div>
      {bot && bot.company.crmSolution === "servman" && servmanInfo ? (
        <div className={classes.container}>
          Servman Category{" "}
          <select
            onChange={(e) => {
              const cat = servmanInfo.find((cat) => cat.id === e.target.value);
              setCurrentServmanCat(cat);
              if (cat.problems.length) {
                setCurrentServmanProblem(cat.problems[0]);
              }
            }}
          >
            {servmanInfo.map((info) => {
              return (
                <option key={`cat_id${info.id}`} value={info.id}>
                  {info.name}
                </option>
              );
            })}
          </select>
          <br />
          Servman Problem{" "}
          <select
            onChange={(e) => {
              const prob = currentServmanCat.problems.find(
                (prob) => prob.id === e.target.value,
              );

              setCurrentServmanProblem(prob);
            }}
          >
            {currentServmanCat?.problems?.map((probli) => {
              return (
                <option
                  key={`prob_id${probli.id}${Math.random()}`}
                  value={probli.id}
                >
                  {probli.name} (servman-question-{probli.id})
                </option>
              );
            })}
          </select>
        </div>
      ) : null}

      {bot && bot.company.crmSolution === "servman" && servmanInfo && (
        <div className={classes.container}>
          Add Element{" "}
          <select
            onChange={(e) => {
              setAddType(e.target.value);
            }}
          >
            <option value="message">Message</option>
            <option value="pretty-button-group">Pretty Button Group</option>
            <option value="user-input">User Input</option>
          </select>
          <br />
          <br />
          {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
          <button
            onClick={() => {
              let newStep = {};
              if (addType === "message") {
                if (currentServmanProblem) {
                  newStep = {
                    id: `servman-question-${currentServmanProblem.id}`,
                    message: `servman_question_${currentServmanProblem.name}`,
                    trigger: "ask-additional-information",
                  };
                  const newPreModal = [...preModal];
                  newPreModal[0].steps = [...newPreModal[0].steps, newStep];
                  setPreModal(newPreModal);

                  setReload(true);
                } else {
                  newStep = {
                    id: `premodal_question_${Math.random()}`,
                    message: `premodal_question_${Math.random()}`,
                    trigger: "next-step",
                  };
                  const newPreModal = [...preModal];
                  newPreModal[0].steps = [...newPreModal[0].steps, newStep];
                  setPreModal(newPreModal);
                }
              } else if (addType === "pretty-button-group") {
                if (currentServmanProblem) {
                  newStep = {
                    asMessage: false,
                    id: `servman-question-${currentServmanProblem.id}`,
                    waitAction: true,
                    component: {
                      type: "pretty-button-group",
                      buttons: [
                        {
                          value: "Yes",
                          label: "Yes",
                        },
                        {
                          value: "No",
                          label: "No",
                        },
                      ],
                    },

                    trigger: "ask-additional-information",
                    setVariable: "trackQuestion ",
                  };
                  const newPreModal = [...preModal];
                  newPreModal[0].probing_questions = [
                    ...newPreModal[0].probing_questions,
                    newStep,
                  ];
                  setPreModal(newPreModal);
                } else {
                  newStep = {
                    asMessage: false,
                    id: `premodal_question_${Math.random()}`,
                    waitAction: true,
                    buttons: [
                      {
                        value: "Yes",
                        label: "Yes",
                      },
                      {
                        value: "No",
                        label: "No",
                      },
                    ],
                    trigger: "next-step",
                    setVariable: "trackQuestion ",
                  };
                  const newPreModal = [...preModal];
                  newPreModal[0].probing_questions = [
                    ...newPreModal[0].probing_questions,
                    newStep,
                  ];
                  setPreModal(newPreModal);
                }

                setReload(true);
              } else if (addType === "user-input") {
                const sid = currentServmanProblem
                  ? `servman-question-${currentServmanProblem.id}`
                  : `id-${Math.random()}`;
                const strigger = currentServmanProblem
                  ? "ask-additional-information"
                  : "next-service";
                newStep = {
                  id: sid,
                  component: {
                    type: "userinput",
                    placeholder: "placeholder text",
                  },
                  waitAction: true,
                  setVariable: "setVariableName",
                  trigger: strigger,
                };

                const newPreModal = [...preModal];
                newPreModal[0].probing_questions = [
                  ...newPreModal[0].probing_questions,
                  newStep,
                ];
                setPreModal(newPreModal);

                setReload(true);
              }
            }}
          >
            Add
          </button>
        </div>
      )}
    </Container>
  ) : null;
}
