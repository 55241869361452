import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import _ from "lodash";

const useListCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [lastKey, setLastKey] = useState(null);

  const fetchPageCompanies = useCallback(async () => {
    try {
      setLoading(true);

      let queryString = "pageSize=500";
      const {
        id: lastEvaluatedKeyId,
        companyName: lastEvaluatedKeyCompanyName,
        version: lastEvaluatedKeyVersion,
      } = lastKey || {};

      if (lastEvaluatedKeyId) {
        queryString += `&lastEvaluatedKeyId=${lastEvaluatedKeyId}`;
      }
      if (lastEvaluatedKeyCompanyName) {
        queryString += `&lastEvaluatedKeyCompanyName=${lastEvaluatedKeyCompanyName}`;
      }
      if (lastEvaluatedKeyVersion) {
        queryString += `&lastEvaluatedKeyVersion=${lastEvaluatedKeyVersion}`;
      }

      const { companies: fetchedCompanies, lastEvaluatedKey } = await API.get(
        "bridgeApi",
        `/v2/companies?${queryString}`,
      );

      const sortedCompanies = [...companies, ...fetchedCompanies];
      sortedCompanies.sort((a, b) =>
        a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()),
      );
      setCompanies(sortedCompanies);
      setLastKey(lastEvaluatedKey);

      setLoading(false);
      return sortedCompanies;
    } catch (error) {
      console.log("Error fetching companies:", error);
      setLoading(false);
      return [];
    }
  }, [companies, lastKey]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchPageCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { companies, lastKey, loadMore: fetchPageCompanies, loading };
};

export default useListCompanies;
