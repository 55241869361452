import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, makeStyles, createStyles, Button } from "@material-ui/core";

import Title from "../../dashboard/Title";
import { API, graphqlOperation } from "aws-amplify";

import { getBotVersioned } from "../../../graphql/queries";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import { createNewVersion } from "../../../utils/versionUtilFuncs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyBotServiceQuestions() {
  const { botId } = useParams();
  const classes = useStyles();

  const [bot, setBot] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [serviceQuestions, setServiceQuestions] = useState({});

  const fetchBot = async () => {
    try {
      const fetch = await API.graphql(
        graphqlOperation(getBotVersioned, {
          id: botId,
          version: "v0",
        }),
      );
      if (fetch.data.getBotVersioned.serviceQuestions) {
        try {
          fetch.data.getBotVersioned.serviceQuestions = JSON.parse(
            fetch.data.getBotVersioned.serviceQuestions,
          );
        } catch (e) {
          fetch.data.getBotVersioned.serviceQuestions = null;
        }
      } else {
        fetch.data.getBotVersioned.serviceQuestions = JSON.parse("[]");
      }
      setBot(fetch.data.getBotVersioned);
      setServiceQuestions(fetch.data.getBotVersioned.serviceQuestions);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  useEffect(() => {
    // fetch customer record here
    fetchBot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateBotCb = async () => {
    const payload = {
      ...bot,
      serviceQuestions: JSON.stringify(serviceQuestions, null, 2),
    };

    delete payload.company;
    delete payload.sections;

    if (payload.serviceQuestions.serviceQuestions) {
      payload.serviceQuestions = payload.serviceQuestions.serviceQuestions;
    }
    try {
      await createNewVersion(bot.id, "bot", payload);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };

  return loaded ? (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>{bot.botTitle} Learn More</Title>
      </div>
      <div className={classes.container}>
        <Button
          style={{ marginRight: 10, marginBottom: 20 }}
          onClick={updateBotCb}
          color="primary"
          variant="contained"
        >
          Save Service Questions
        </Button>
        <Editor
          value={serviceQuestions}
          onChange={(e) => setServiceQuestions(e)}
        />
      </div>
    </Container>
  ) : null;
}
