import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { API, graphqlOperation } from "aws-amplify";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import { getProgramVersioned } from "../../../graphql/queries";
import programMonths from "./program-months";
import { Link } from "react-router-dom";

export default function ProgramListTable(props) {
  const { companyId, programs, classes } = props;
  const [loadingServices, setLoadingServices] = useState(true);
  const formatMonth = (val) => {
    return programMonths.find((i) => i.value === val).name;
  };
  const [servicesByProgramID, setServicesByProgramId] = useState({});

  useEffect(() => {
    async function fn(programs) {
      for (let i = 0; i < programs.length; i++) {
        const getServices = await API.graphql(
          graphqlOperation(getProgramVersioned, {
            id: programs[i].id,
            version: "v0",
          }),
        );
        setServicesByProgramId((servicesByProgramID) => {
          return {
            ...servicesByProgramID,
            [programs[i].id]:
              getServices.data.getProgramVersioned.services.items,
          };
        });
      }
      setLoadingServices(false);
    }
    fn(programs);
  }, [programs]);

  return (
    <React.Fragment>
      <div classes={classes.root}>
        {programs?.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Program Name</TableCell>
                <TableCell>Program Description</TableCell>
                <TableCell>Program Type</TableCell>
                <TableCell>Active?</TableCell>
                <TableCell>Program/Upsell</TableCell>
                <TableCell>Program Start</TableCell>
                <TableCell>Program End</TableCell>
                <TableCell>Services</TableCell>
                <TableCell>Pricing Attached</TableCell>
                <TableCell>Use Probing Questions</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {programs.map((row) => {
                const services = servicesByProgramID[row.id]
                  ? servicesByProgramID[row.id].length
                  : [];
                const notAttached = row.useRealGreenPricingEngine
                  ? !row.crmProgamCode
                  : servicesByProgramID[row.id]
                    ? servicesByProgramID[row.id].filter((i) => !i.priceTableId)
                        .length
                    : 0;
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.programName}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          maxHeight: 60,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.programDescription}
                      </div>
                    </TableCell>
                    <TableCell>{row.programType}</TableCell>
                    <TableCell>
                      {row.active ? (
                        <b style={{ color: "green" }}>YES</b>
                      ) : (
                        <b style={{ color: "red" }}>NO</b>
                      )}
                    </TableCell>
                    <TableCell>{row.isUpsell ? "Upsell" : "Program"}</TableCell>
                    <TableCell>{formatMonth(row.programStartMonth)}</TableCell>
                    <TableCell>{formatMonth(row.programEndMonth)}</TableCell>
                    <TableCell>
                      {loadingServices ? "Loading..." : services}
                    </TableCell>
                    <TableCell>
                      {loadingServices ? (
                        "Loading..."
                      ) : !notAttached ? (
                        <b style={{ color: "green" }}>YES</b>
                      ) : (
                        <b style={{ color: "red" }}>NO</b>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.useProbingQuestions ? (
                        <b style={{ color: "green" }}>YES</b>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/company/${companyId}/program/${row.id}`}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Programs</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

ProgramListTable.propTypes = {
  companyId: PropTypes.array,
  classes: PropTypes.object,
  programs: PropTypes.array,
};
