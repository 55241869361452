import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";

const TextFieldWithTooltip = ({
  id,
  label,
  fullWidth,
  toolTipDescription,
  value,
  onChange,
}) => {
  const _onChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Box sx={{ my: 2 }}>
      <InputLabel
        style={{
          fontSize: "0.8rem",
          display: "flex",
          flex: "row",
          alignItems: "center",
        }}
        htmlFor={id}
      >
        {label}
        {toolTipDescription ? (
          <Tooltip title={toolTipDescription}>
            <InfoIcon style={{ marginLeft: 4, fontSize: 18 }} />
          </Tooltip>
        ) : null}
      </InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id={id}
            variant="standard"
            fullWidth={fullWidth}
            value={value}
            onChange={_onChange}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export default TextFieldWithTooltip;
