import { API, graphqlOperation } from "aws-amplify";
import { getCompanyVersioned } from "./../graphql/queries";
import { proxy } from "valtio";

export const activeCompanyContext = proxy({
  company: null,
  id: null,
  version: null,
  loading: false,
});

// actions
export const fetchAndSetActiveCompanyContext = async ({
  companyId,
  version = "v0",
}) => {
  if (!companyId) {
    return null;
  }
  activeCompanyContext.loading = true;
  const fetchedData = await API.graphql(
    graphqlOperation(getCompanyVersioned, {
      id: companyId,
      version,
    }),
  );
  const company = fetchedData?.data?.getCompanyVersioned || null;
  if (company) {
    activeCompanyContext.company = company;
    activeCompanyContext.id = companyId;
    activeCompanyContext.version = version;
  }
  activeCompanyContext.loading = false;
  return company;
};

// set default function
export const setDefaultActiveCompanyContext = () => {
  activeCompanyContext.company = null;
  activeCompanyContext.id = null;
  activeCompanyContext.version = null;
  activeCompanyContext.loading = false;
};
