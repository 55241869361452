import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import * as cloningFuncs from "./cloning.js";
import { useHistory } from "react-router-dom";
import { useGetActiveCompanyContext } from "../../../hooks/companies/useGetActiveCompanyContext.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function CloningModal({ open, handleClose, companyId }) {
  const { company: activeCompany } = useGetActiveCompanyContext({
    companyId,
  });
  const history = useHistory();

  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [cloning, setCloning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {error
          ? `${error}`
          : cloning
            ? `Cloning into ${name}`
            : "Enter the name of the cloned company."}
      </DialogTitle>
      <DialogContent>
        {cloning ? (
          <div style={{ marginBottom: 25 }}>
            <p>{progressMessage}</p>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        ) : (
          <TextField
            margin="dense"
            id="name"
            label="Enter Company Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </DialogContent>
      {!cloning && (
        <DialogActions>
          <Button
            onClick={() => {
              setCloning(false);
              setProgress(0);
              setProgressMessage("");
              setName("");
              setError("");
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!name}
            onClick={async () => {
              setCloning(true);
              setError("");
              const result = await cloningFuncs.cloneCompany(
                activeCompany,
                name,
                setProgress,
                setProgressMessage,
              );
              if (result?.err) {
                setError(result.message);
              } else if (result?.message) {
                history.push(`/company/${result.message}`);
              }
              setCloning(false);
              setProgress(0);
              setProgressMessage("");
              setName("");
              handleClose();
            }}
            color="primary"
          >
            Agree
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
