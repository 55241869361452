import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems, customerListItems } from "./listItems";

import * as _ from "lodash";

import { Switch, Route } from "react-router-dom";
import { useSnapshot } from "valtio";
import { activeCompanyContext } from "../contexts/activeCompany.contexts";
import { useLocation } from "react-router-dom";

const Sidebar = ({ setOpen, open, classes }) => {
  const { pathname } = useLocation();
  const { company } = useSnapshot(activeCompanyContext);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <Switch>
          {pathname !== "/company/new" ? (
            <Route
              path="/company/:companyId"
              render={() => {
                return customerListItems(company);
              }}
            />
          ) : null}
          <Route path="/">{mainListItems}</Route>
        </Switch>
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
