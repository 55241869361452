import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  Snackbar,
} from "@material-ui/core";

import { API, graphqlOperation } from "aws-amplify";
import { createOffer } from "../../../graphql/mutations";

import { useParams } from "react-router-dom";
import Title from "../../dashboard/Title";
import { OfferInfoForm } from "./offer-info-form";
import { useHistory } from "react-router-dom";
import { fetchAndSetActiveCompanyContext } from "../../../contexts/activeCompany.contexts";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyOfferNew() {
  const classes = useStyles();
  const history = useHistory();
  const { companyId } = useParams();

  const [snackOpened, openSnack] = useState(false);
  const snackMessage = "Offer Saved! Refreshing";
  const [offerId, setOfferId] = useState(0);
  const [programs, setPrograms] = useState([]);
  const [priceTables, setPriceTables] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchCompany = useCallback(async () => {
    try {
      const activeCompany = await fetchAndSetActiveCompanyContext({
        companyId,
      });
      setPrograms(activeCompany?.programs?.items);
      setPriceTables(activeCompany?.priceTables?.items);
      setLoaded(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  }, [companyId]);

  useEffect(() => {
    // fetch customer record here
    fetchCompany();
  }, [fetchCompany]);

  const submitNewOffer = async (offer) => {
    const insertOffer = { ...offer };

    insertOffer.programs = insertOffer.programs
      .filter((i) => i)
      .map((p) => {
        if (p.discountPriceTableId === "") {
          delete p["discountPriceTableId"];
        }
        return p;
      });

    insertOffer.programs = JSON.stringify(insertOffer.programs);
    const payload = Object.assign({}, insertOffer, {
      offerCompanyId: companyId,
    });
    try {
      delete payload.__typename;
      const fetch = await API.graphql(
        graphqlOperation(createOffer, { input: payload }),
      );
      const id = fetch.data.createOffer.id;

      setOfferId(id);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };
  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Link color="inherit" href={`/company/${companyId}/offers`}>
          Offers
        </Link>
        <Typography color="textPrimary">New Offer</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Title>New Offer</Title>
      </div>
      <div className={classes.container}>
        {!loaded ? null : (
          <OfferInfoForm
            submit={submitNewOffer}
            programs={programs}
            priceTables={priceTables}
          />
        )}
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={1500}
        onClose={() => {
          openSnack(false);
          history.push(`/company/${companyId}/offer/${offerId}`);
        }}
        message={snackMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  );
}
