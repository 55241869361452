import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import {
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import useDebounce from "../../utils/debounce";
import useListCompanies from "../../hooks/companies/useListCompanies";
import Title from "../dashboard/Title";
import { setDefaultActiveCompanyContext } from "../../contexts/activeCompany.contexts";

export default function CompanyListTable(props) {
  const { companies, lastKey, loadMore, loading } = useListCompanies();
  const { classes } = props;
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filterText, setFilterText] = useState("");

  const debouncedSearchTerm = useDebounce(filterText, 500);

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  useEffect(() => {
    if (companies.length) {
      const lowerCaseSearchTerm = debouncedSearchTerm.trim().toLowerCase();
      const comps = companies.filter(
        (i) =>
          i.companyName.toLowerCase().includes(lowerCaseSearchTerm) ||
          i.customerName.toLowerCase().includes(lowerCaseSearchTerm) ||
          i.customerEmail.toLowerCase().includes(lowerCaseSearchTerm),
      );

      setFilteredCompanies(comps);
    }
  }, [companies, debouncedSearchTerm]);

  useEffect(() => {
    setDefaultActiveCompanyContext();
  }, []);

  const stripImageMeta = (src) => {
    return src ? src.split("?")[0] : "";
  };

  const searchRecords = (e) => {
    setFilterText(e.target.value);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Title>Companies</Title>
        <Button
          style={{ marginLeft: "auto" }}
          color="primary"
          variant="contained"
          component={Link}
          to="/company/new"
        >
          New +
        </Button>
      </div>
      <div classes={classes.root}>
        <TextField
          onChange={searchRecords}
          placeholder="Search..."
          style={{
            margin: "0 auto",
            maxWidth: 900,
          }}
        />
        {companies.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Company</TableCell>
                <TableCell>Contact Name</TableCell>
                <TableCell>Number of Bots</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCompanies.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.companyLogo ? (
                      <img
                        style={{ width: 80 }}
                        src={stripImageMeta(row.companyLogo)}
                        alt=""
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>{row.customerName}</TableCell>
                  {row.bots?.items && (
                    <TableCell>{row.bots.items.length}</TableCell>
                  )}

                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/company/${row.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Companies</Typography>
        )}

        {loading ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress
              style={{ height: "24px", width: "24px" }}
              color="white"
            />
          </div>
        ) : lastKey ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() => loadMore()}
            >
              Load More
            </Button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

CompanyListTable.propTypes = {
  customers: PropTypes.array,
  classes: PropTypes.object,
};
