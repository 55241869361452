import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";

import { Switch, Route, Redirect } from "react-router-dom";
import { CompanyDetail } from "../company/company-detail";
import { CompanyBranding } from "../company/company-branding";
import { CompanyDataServices } from "../company/company-data-services";

import CompanyListTable from "../company/company-list-table";
import { CompanyNew } from "../company/company-new";
import CompanyBotsPage from "../company/bots/company-bots-page";
import { CompanyBotNew } from "../company/bots/bot-new";
import { CompanyBotDetail } from "../company/bots/bot-detail";
import CompanyProgramPage from "../company/programs/company-programs-page";
import { CompanyProgramNew } from "../company/programs/program-new";
import { CompanyProgramDetail } from "../company/programs/program-detail";
import CompanyPriceTablePage from "../company/price-tables/company-price-table-page";
import { CompanyPriceTableNew } from "../company/price-tables/company-price-table-new";
import { CompanyPriceTableDetail } from "../company/price-tables/price-table-detail";
import ProgramProbingQuestionsPage from "../company/programs/probing-questions/company-probing-questions";
import { CompanyBotLearnMore } from "../company/bots/bot-learn-more";
import { CompanyBotObjections } from "../company/bots/bot-objections";
import { CompanyBotServiceQuestions } from "../company/bots/bot-service-questions";

import { RealGreenIntegration } from "../company/intergrations/real-green-page";
import { SalesCenterIntegration } from "../company/intergrations/salesCenter/sales-center-page.js";
import { PestPacPage } from "../company/intergrations/PestPacPage.jsx";

import CompanyOffersPage from "../company/offers/company-offers-page";
import { CompanyOfferNew } from "../company/offers/offer-new";
import { CompanyOfferDetail } from "../company/offers/offer-detail";
import { LawnbotDashboardIntegration } from "../company/intergrations/lawnbot-dashboard";
import { CompanyBotPreModal } from "../company/bots/bot-pre-modal";

import { GoogleSheetIntegration } from "../company/intergrations/google-sheets-page";
import Sidebar from "../../layouts/Sidebar.js";
import Topbar from "../../layouts/Topbar.js";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Topbar open={open} toggleOpen={toggleOpen} classes={classes} />
      <Sidebar open={open} setOpen={setOpen} classes={classes} />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Switch>
          <Route exact path="/company/new">
            <CompanyNew />
          </Route>
          {/* Create program */}
          <Route exact path="/company/:companyId/program/new">
            <CompanyProgramNew />
          </Route>
          <Route
            exact
            path="/company/:companyId/program/:programId/probing-questions"
          >
            <ProgramProbingQuestionsPage />
          </Route>
          {/* Update program */}
          <Route exact path="/company/:companyId/program/:programId">
            <CompanyProgramDetail />
          </Route>
          <Route exact path="/company/:companyId/bot/new">
            <CompanyBotNew />
          </Route>
          <Route exact path="/company/:companyId/price-table/new">
            <CompanyPriceTableNew />
          </Route>
          <Route exact path="/company/:companyId/price-table/:priceTableId">
            <CompanyPriceTableDetail />
          </Route>
          <Route path="/company/:companyId/bot/:botId/learn-more">
            <CompanyBotLearnMore />
          </Route>
          <Route path="/company/:companyId/bot/:botId/objections">
            <CompanyBotObjections />
          </Route>
          <Route path="/company/:companyId/bot/:botId/pre-modal">
            <CompanyBotPreModal />
          </Route>
          <Route path="/company/:companyId/bot/:botId/service-questions">
            <CompanyBotServiceQuestions />
          </Route>
          <Route path="/company/:companyId/bot/:botId">
            <CompanyBotDetail />
          </Route>
          <Route path="/company/:companyId/programs">
            <CompanyProgramPage />
          </Route>
          <Route path="/company/:companyId/data-services">
            <CompanyDataServices />
          </Route>
          <Route path="/company/:companyId/offers">
            <CompanyOffersPage />
          </Route>
          <Route path="/company/:companyId/offer/new">
            <CompanyOfferNew />
          </Route>
          <Route path="/company/:companyId/offer/:offerId">
            <CompanyOfferDetail />
          </Route>
          <Route path="/company/:companyId/price-tables">
            <CompanyPriceTablePage />
          </Route>
          <Route path="/company/:companyId/bots">
            <CompanyBotsPage />
          </Route>
          <Route path="/company/:companyId/branding">
            <CompanyBranding />
          </Route>
          <Route path="/company/:companyId/real-green">
            <RealGreenIntegration />
          </Route>
          <Route path="/company/:companyId/sales-center">
            <SalesCenterIntegration />
          </Route>
          <Route path="/company/:companyId/pest-pac">
            <PestPacPage />
          </Route>
          <Route path="/company/:companyId/servman">
            <div>Servman</div>
          </Route>
          <Route path="/company/:companyId/google-sheets">
            <GoogleSheetIntegration />
          </Route>
          <Route path="/company/:companyId/lawnbot-dashboard">
            <LawnbotDashboardIntegration />
          </Route>
          <Route path="/company/:companyId">
            <CompanyDetail />
          </Route>
          <Route path="/companies">
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <CompanyListTable classes={classes} />
                  </Paper>
                </Grid>
              </Grid>
              <Box pt={4}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {"Copyright © "}
                  <Link color="inherit" href="https://goservicebot.com/">
                    Service Bot
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
            </Container>
          </Route>
          <Route path="/">
            <Redirect to="/companies" />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
