import React, { useState } from "react";
import { MenuItem, Button, InputAdornment } from "@material-ui/core";
import { PropTypes } from "prop-types";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

export function PriceTableInfoForm(props) {
  const { action = "Save", deletePriceTableCheck } = props;

  const years = [...Array(4).keys()].map((i) => 2020 + i);
  const buttonStyle = {
    marginTop: 20,
    marginRight: 10,
  };

  const submitForm = () => {
    // const type = serviceTypes.find((i) => i.name === program.programType);
    // const payload = Object.assign({}, program, {programType: type});
    props.submit(priceTable);
  };
  const updateForm = (e) => {
    const key = e.target.id;
    let val = e.target.value;
    if (key === "prePayDiscount") {
      val = val || 0;
      val = Number.parseFloat(val);
    }
    const payload = {};
    payload[key] = val;
    setPriceTable({ ...priceTable, ...payload });
  };

  const [priceTable, setPriceTable] = useState(
    props.priceTable || {
      name: "",
      year: 2020,
      prePayDiscount: 0.0,
      serviceType: "Mowing",
    },
  );

  return (
    <React.Fragment>
      <ValidatorForm onSubmit={submitForm}>
        <TextValidator
          id="name"
          label="Price Table Name"
          value={priceTable.name}
          style={{ margin: 8, width: 300 }}
          placeholder="2020 Mowing Price Table"
          margin="normal"
          onChange={updateForm}
          onBlur={updateForm}
          errorMessages={["this field is required"]}
          InputLabelProps={{
            shrink: true,
          }}
          validators={["required"]}
        />
        <div>
          <SelectValidator
            labelid="year"
            id="year"
            style={{ marginTop: 24, marginRight: 10, marginLeft: 10 }}
            value={priceTable.year}
            onChange={(e) => {
              const payload = Object.assign({}, priceTable, {
                year: e.target.value,
              });
              setPriceTable(payload);
            }}
            errorMessages={["this field is required"]}
            placeholder="Service Year"
            validators={["required"]}
          >
            {years.map((i) => {
              const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
              return (
                <MenuItem key={key} value={i}>
                  {i}
                </MenuItem>
              );
            })}
          </SelectValidator>
        </div>

        <div>
          <TextValidator
            id="prePayDiscount"
            label="PrePay Discount"
            value={priceTable.prePayDiscount}
            style={{ margin: 8, width: 300 }}
            placeholder="3%"
            margin="normal"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            InputLabelProps={{
              shrink: true,
            }}
            errorMessages={["this field is required"]}
            validators={["required"]}
            onChange={updateForm}
            onBlur={updateForm}
          />
        </div>

        {/* <div>

      <SelectValidator 
            labelid="serviceType"
            id="serviceType"
            style={{marginTop: 24, marginRight: 10, marginLeft: 10}}
            value={priceTable.serviceType}
            onChange={(e) => { 
                const payload = Object.assign({}, priceTable, {serviceType: e.target.value })
                setPriceTable(payload);
             }}
            errorMessages={['this field is required']}
           validators={['required']}
        >
            {serviceTypes.map(i => {
                const key = "menu-item-" + Math.floor(Math.random() * 100000);
              return (<MenuItem key={key} value={i.name}>{i.name}</MenuItem>)
          })}
        </SelectValidator>
 
        
      </div> */}

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          {action} Price Table
        </Button>
        <Button
          onClick={deletePriceTableCheck}
          type="button"
          color="delete"
          variant="contained"
          style={buttonStyle}
        >
          Delete Price Table
        </Button>
      </ValidatorForm>
    </React.Fragment>
  );
}

PriceTableInfoForm.propTypes = {
  priceTable: PropTypes.object,
  submit: PropTypes.func,
};
