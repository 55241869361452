import React, { useEffect, useState, useCallback } from "react";
import {
  Modal,
  Typography,
  Snackbar,
  CircularProgress,
  Button,
  TextField,
  Paper,
  Box,
} from "@material-ui/core";
import { API } from "aws-amplify";

export function ClearDashboard(props) {
  const { companyId } = props;
  const [activityLog, setActivityLog] = useState([]);
  const [quotesCount, setQuotesCount] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackOpened, openSnack] = useState(false);

  const openDashboard = useCallback(() => {
    setButtonDisabled(true);
    setIsLoading(false);
    setIsOpen(true);
  }, [setIsOpen]);

  const onConfirmationTextChange = useCallback(
    (text) => {
      if (text === "YES DELETE") {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    },
    [setButtonDisabled],
  );

  const loadQuoteCount = async () => {
    const data = await API.get(
      "bridgeApi",
      `/dashboard/${companyId}/clear`,
      {},
    );

    if (data.error) {
      console.log(data.message);
    } else {
      setQuotesCount(data?.results?.count || 0);
    }
  };

  const loadActivityLog = async () => {
    const data = await API.get(
      "bridgeApi",
      `/dashboard/activityLog/${companyId}/clear`,
      {},
    );

    if (data.error) {
      console.log(data.message);
    } else {
      setActivityLog(
        data?.results.sort((a, b) =>
          a.createdTime > b.createdTime
            ? -1
            : a.createdTime < b.createdTime
              ? 1
              : 0,
        ) || [],
      );
    }
  };

  const clearDashboard = useCallback(
    async (companyId) => {
      setIsLoading(true);
      await API.del("bridgeApi", `/dashboard/${companyId}/clear`, {});

      await loadActivityLog();
      await loadQuoteCount();
      setIsLoading(false);
      setIsOpen(false);
      openSnack(true);
    },
    [setIsOpen, loadActivityLog, openSnack],
  );

  useEffect(() => {
    loadActivityLog();
    loadQuoteCount();
  }, []);

  return (
    <div style={{ marginTop: 15 }}>
      <div style={{ width: "100%", marginLeft: 10 }}>
        <h4>Clear Dashboard</h4>
      </div>
      {!quotesCount && (
        <div>
          <p>
            There are no quotes on the dashboard at this time. Nothing to clear.
          </p>
        </div>
      )}
      <div>
        {!!quotesCount && (
          <div>
            <p style={{ color: "red" }}>
              WARNING - THIS ACTION CANNOT BE UNDONE
            </p>
            <Button color="primary" variant="contained" onClick={openDashboard}>
              DELETE ALL DASHBOARD HISTORY
            </Button>
          </div>
        )}
        {!!activityLog?.length && (
          <div>
            <h4>Clear Dashboard Audit Trail</h4>
            <ul>
              {activityLog.map((logItem) => (
                <li key={logItem.id}>{logItem.logMessage}</li>
              ))}
            </ul>
          </div>
        )}
        <Modal open={isOpen} height="100%">
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Paper
              elevation={3}
              style={{ padding: "16px", height: "225px", width: "500px" }}
            >
              <Typography>
                You will be deleting {quotesCount} quotes and this action will
                be logged.
              </Typography>
              <Typography style={{ padding: "16px 0px" }}>
                Please enter YES DELETE in the field below and click the DELETE
                ALL DASHBOARD HISTORY button.
              </Typography>
              <Box>
                <TextField
                  defaultValue=""
                  placeholder="YES DELETE"
                  onChange={(element) =>
                    onConfirmationTextChange(element.target.value)
                  }
                />
              </Box>
              <Box pt={2} display="flex">
                <Box>
                  <Button variant="contained" onClick={() => setIsOpen(false)}>
                    Close
                  </Button>
                </Box>

                <Box flex="1" textAlign="right" alignItems="end">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={buttonDisabled || isLoading}
                    onClick={() => clearDashboard(companyId)}
                  >
                    {isLoading && (
                      <CircularProgress
                        style={{ height: "24px", width: "24px" }}
                        color="white"
                      />
                    )}
                    {!isLoading && "DELETE ALL DASHBOARD HISTORY"}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
        <Snackbar
          open={snackOpened}
          autoHideDuration={2500}
          onClose={() => {
            openSnack(false);
          }}
          message="Dashboard successfully cleared."
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        />
      </div>
    </div>
  );
}
