import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  createStyles,
  Container,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import BotListTable from "./bot-list-table";
import { useParams } from "react-router-dom";
import { Link as NavLink } from "react-router-dom";
import { useGetActiveCompanyContext } from "../../../hooks/companies/useGetActiveCompanyContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    newButton: {
      position: "relative",
      float: "right",
      marginRight: 20,
    },
  }),
);

export default function CompanyBotsPage() {
  const { companyId } = useParams();
  const { company: activeCompany } = useGetActiveCompanyContext({
    companyId,
  });
  const loaded = !!activeCompany;
  const bots = activeCompany?.bots?.items || [];
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>
        <Typography color="textPrimary">Bots</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <Button
          className={classes.newButton}
          color="primary"
          variant="contained"
          component={NavLink}
          to={`/company/${companyId}/bot/new`}
        >
          New +
        </Button>
        {loaded ? (
          <BotListTable companyId={companyId} bots={bots} classes={classes} />
        ) : null}
      </div>
    </Container>
  );
}
