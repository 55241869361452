import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify"; // Assuming AWS Amplify is used
import { getProgramVersioned } from "../../graphql/queries";
import getProgramTypeTemplateQuestions from "../../utils/getProgramTypeTemplateQuestions";

const useProgramProbingQuestions = (programId) => {
  const [program, setProgram] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [probingQuestions, setProbingQuestions] = useState([]);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const fetch = await API.graphql(
          graphqlOperation(getProgramVersioned, {
            id: programId,
            version: "v0",
          }),
        );

        const programData = fetch.data.getProgramVersioned;
        setProgram(programData);

        if (programData.probingQuestionsJson !== null) {
          console.log("Has initial probing questions");
          const parsedQuestions = JSON.parse(programData.probingQuestionsJson);
          setProbingQuestions(parsedQuestions);
        } else {
          console.log("Does not have initial probing questions");
          setProbingQuestions(
            getProgramTypeTemplateQuestions(programData.programType),
          );
        }
      } catch (e) {
        setProbingQuestions(
          getProgramTypeTemplateQuestions(
            fetch?.data?.getProgramVersioned?.programType,
          ),
        );
      }

      setLoaded(true);
    };
    fetchProgram();
  }, [programId]);

  return { program, probingQuestions, setProbingQuestions, loaded };
};

export default useProgramProbingQuestions;
