import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import "./App.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";

import { withAuthenticator } from "aws-amplify-react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Router>
        <Dashboard />
      </Router>
      <ToastContainer />
    </>
  );
}

export default withAuthenticator(App);
