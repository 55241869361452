import React, { useState } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useSnapshot } from "valtio";
import { useLocation } from "react-router-dom";

import { CloningModal } from "../pages/dashboard/cloning/CloningModal.jsx";
import { activeCompanyContext } from "../contexts/activeCompany.contexts";
import { createTitle } from "../utils/createTitle.js";

const Topbar = ({ classes, toggleOpen, open }) => {
  const { company } = useSnapshot(activeCompanyContext);
  const { pathname } = useLocation();
  const isOnMainCompanyPage = /^\/company\/[^/]+$/.test(pathname);

  const [openCloningModal, setOpenCloningModal] = useState(false);

  const handleCloningModalOpen = () => setOpenCloningModal(true);
  const handleCloningModalClose = () => setOpenCloningModal(false);

  return (
    <>
      <CloningModal
        handleClose={handleCloningModalClose}
        open={openCloningModal}
        companyId={window.location.pathname.split("/")[2]}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {createTitle(company?.companyName, pathname)}
          </Typography>
          {company && isOnMainCompanyPage && (
            <Button
              color="secondary"
              style={{ backgroundColor: "white" }}
              onClick={handleCloningModalOpen}
            >
              Clone Company
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
