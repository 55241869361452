import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Snackbar,
} from "@material-ui/core";

import Title from "../dashboard/Title";
import { CompanyInfoForm } from "./company-info-form";
import { createNewVersion } from "../../utils/versionUtilFuncs";
import { useGetActiveCompanyContext } from "../../hooks/companies/useGetActiveCompanyContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export function CompanyDetail() {
  const { companyId } = useParams();

  const { company: activeCompany, loading } = useGetActiveCompanyContext({
    companyId,
  });

  const classes = useStyles();

  const [snackOpened, openSnack] = useState(false);

  const updateCompanyCb = async (company) => {
    try {
      await createNewVersion(companyId, "company", company);
      openSnack(true);
    } catch (error) {
      console.log("error: ", error.errors[0].message);
    }
  };
  return (
    <Container className={classes.root}>
      <div className={classes.container}>
        <Title>Customer Information</Title>
      </div>
      <div className={classes.container}>
        {!loading ? (
          <CompanyInfoForm
            company={activeCompany}
            submit={updateCompanyCb}
            action={"UPDATE"}
          />
        ) : (
          "Loading"
        )}
      </div>
      <Snackbar
        open={snackOpened}
        autoHideDuration={3000}
        onClose={() => {
          openSnack(false);
        }}
        message="Company Saved"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Container>
  );
}
