import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";

export default function PriceTableListTable(props) {
  const { companyId, priceTables, classes } = props;

  return (
    <React.Fragment>
      <div classes={classes.root}>
        {priceTables?.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>PrePay Discount</TableCell>
                <TableCell># Rows</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {priceTables?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.prePayDiscount}</TableCell>
                  {row.rows && <TableCell>{row.rows.items.length}</TableCell>}
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/company/${companyId}/price-table/${row.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Price Tables</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

PriceTableListTable.propTypes = {
  companyId: PropTypes.string,
  classes: PropTypes.object,
  priceTables: PropTypes.array,
};
