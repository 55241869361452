import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";

export default function BotListTable(props) {
  const { companyId, bots, classes } = props;

  return (
    <React.Fragment>
      <div classes={classes.root}>
        {bots.length ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Bot Name</TableCell>
                <TableCell>Bot Version</TableCell>
                <TableCell>Bot Status</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {bots.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.botTitle}</TableCell>
                  <TableCell>{row.botName}</TableCell>
                  <TableCell>{row.botVersion}</TableCell>
                  <TableCell>{row.botStatus}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      component={Link}
                      to={`/company/${companyId}/bot/${row.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No Bots</Typography>
        )}
      </div>
    </React.Fragment>
  );
}

BotListTable.propTypes = {
  companyId: PropTypes.string,
  classes: PropTypes.object,
  bots: PropTypes.array,
};
