export const templatedCommercialCleaning = [
  {
    name: "Commercial Cleaning Probing Questions",
    type: "commercial-cleaning",
    steps: [
      {
        id: "commercial-cleaning-entry",
        message: "What is the total number of bedrooms in the home?",
        trigger: "get-commercial-cleaning-bedroom-count",
      },
      {
        id: "commercial-cleaning-bathroom-count",
        message: "What is the total number of bathrooms in the home?",
        trigger: "get-commercial-cleaning-bathroom-count",
      },
      {
        id: "commercial-cleaning-half-bathroom-count",
        message: "What is the total number of half bathrooms in the home?",
        trigger: "get-commercial-cleaning-half-bathroom-count",
      },
      {
        id: "commercial-cleaning-service-frequency",
        message: "How often would you like our service?",
        trigger: "get-commercial-cleaning-service-frequency",
      },
    ],
    probing_questions: [
      {
        id: "get-commercial-cleaning-bedroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of bedrooms (i.e. 3 )",
        },
        waitAction: true,
        setVariable: "commercialCleaningBedroomCount",
        trigger: "commercial-cleaning-bathroom-count",
      },
      {
        id: "get-commercial-cleaning-bathroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of bathrooms (i.e. 2 )",
        },
        waitAction: true,
        setVariable: "commercialCleaningBathroomCount",
        trigger: "commercial-cleaning-half-bathroom-count",
      },
      {
        id: "get-commercial-cleaning-half-bathroom-count",
        component: {
          type: "usernumberinput",
          subtype: "wholeNumber",
          min: 0,
          max: 99,
          placeholder: "Number of half bathrooms (i.e. 2 )",
        },
        waitAction: true,
        setVariable: "commercialCleaningHalfBathroomCount",
        trigger: "commercial-cleaning-service-frequency",
      },
      {
        id: "get-commercial-cleaning-service-frequency",

        component: {
          type: "userinput",
          placeholder:
            "Frequency of Service (i.e. Weekly, Bi-Weekly, Monthly, One Time )",
        },
        waitAction: true,
        setVariable: "commercialCleaningServiceFrequency",
        trigger: "next-service",
      },
    ],
  },
];
