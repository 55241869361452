import DashboardApi from "../../utils/dashboardApi";

export const getCompanyEmployees = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/employees",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyDiscountCodes = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/discounts",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyRoutes = async (apiKey) => {
  try {
    const response = await DashboardApi.get("/integration/real-green/routes", {
      headers: {
        apiKey,
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyPrograms = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/programs",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyMarketingCodes = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/marketing-sources",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallStatuses = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/call-statuses",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanyCallActionReasons = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/call-action-reasons",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCompanies = async (apiKey) => {
  try {
    const response = await DashboardApi.get(
      "/integration/real-green/companies",
      {
        headers: {
          apiKey,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};
