const botTypes = [
  {
    name: "Sales Bot",
    value: "sales",
  },
  {
    name: "Pest Bot",
    value: "pest",
  },
];

export default botTypes;
