import { getValueAddHTML } from "../pages/company/bots/templates/learn-more.template";
import { API, graphqlOperation } from "aws-amplify";
import getProgramTypeTemplateQuestions from "./getProgramTypeTemplateQuestions";

const queries = require("../graphql/queries");
const _ = require("lodash");

function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const publishBot = async (company) => {
  const bots = company.bots.items.map((bot) => {
    if (bot.learnMore && IsJsonString(bot.learnMore)) {
      bot.learnMore = JSON.parse(bot.learnMore);
    }

    if (!bot.learnMore) {
      bot.learnMore = [
        {
          id: "99999",
          name: "learn-more-insert",
          steps: [],
          probing_questions: [],
        },
      ];
    }

    let probing = [];
    if (bot.learnMore) {
      probing = [...bot.learnMore[0].probing_questions].filter(
        (z) => z.id !== "learn-more",
      );
    }

    probing.push({
      id: "learn-more",
      component: {
        type: "html",
        html: getValueAddHTML(company.branding),
      },
      asMessage: false,
      trigger: "company-learn-more-description",
    });
    bot.learnMore[0].probing_questions = probing;

    bot.objections = IsJsonString(bot.objections)
      ? JSON.parse(bot.objections)
      : bot.objections;
    bot.serviceQuestions = IsJsonString(bot.serviceQuestions)
      ? JSON.parse(bot.serviceQuestions)
      : bot.serviceQuestions;
    return bot;
  });

  await Promise.all(
    company.programs.items.map(async (program) => {
      const payload = {
        id: program.id,
        version: "v0",
      };
      try {
        const getServices = await API.graphql(
          graphqlOperation(queries.getProgramVersioned, payload),
        );
        program.services = getServices.data.getProgramVersioned.services;
      } catch (e) {
        console.log(e);
      }

      return program;
    }),
  );

  await Promise.all(
    company.priceTables.items.map(async (priceTable) => {
      const payload = {
        id: priceTable.id,
        version: "v0",
      };
      try {
        const getPricetTable = await API.graphql(
          graphqlOperation(queries.getPriceTableVersioned, payload),
        );
        priceTable.rows = getPricetTable.data.getPriceTableVersioned.rows;
      } catch (e) {
        console.log(e);
      }

      return priceTable;
    }),
  );

  company.bots.items = bots;

  /*  Consolidate the probing questions */
  const uniqueServiceList = _.uniq(
    company.programs.items.map((program) => program.programType),
  );

  // biome-ignore lint/complexity/noForEach: <explanation>
  uniqueServiceList.forEach((service) => {
    const similarTypePrograms = company.programs.items.filter(
      (p) => p.programType === service,
    );

    let defaultProbingQuestions = getProgramTypeTemplateQuestions(
      similarTypePrograms[0].programType,
    );

    if (
      defaultProbingQuestions.length &&
      defaultProbingQuestions[0]?.id === "questions"
    ) {
      defaultProbingQuestions = null;
    } else {
      defaultProbingQuestions = JSON.stringify(
        defaultProbingQuestions,
        null,
        2,
      );
    }

    const programUsingProbingQuestions = similarTypePrograms.find(
      (i) => i.useProbingQuestions,
    );

    similarTypePrograms.forEach((t) => {
      if (programUsingProbingQuestions) {
        t.probingQuestionsJson =
          programUsingProbingQuestions.probingQuestionsJson ||
          defaultProbingQuestions;
      } else {
        t.probingQuestionsJson = null;
      }
    });
  });

  try {
    await API.post("bridgeApi", "/dashboard/bot/publish", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.parse(JSON.stringify(company, null, 2)),
    });

    alert("published");
  } catch (e) {
    alert("Failed to publish!");
  }
};
