import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  makeStyles,
  createStyles,
  Breadcrumbs,
  Link,
  Typography,
  FormLabel,
  Checkbox,
  Card,
  Chip,
  CircularProgress,
} from "@material-ui/core";

import Title from "../../dashboard/Title";
import {
  getCompanyEmployees,
  getCompanyMarketingCodes,
  getCompanyCallActionReasons,
  getCompanyCallStatuses,
  getCompanies,
  getCompanyRoutes,
} from "../../../integrations/realgreen/api_calls";

import { MenuItem, Button } from "@material-ui/core";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { createNewVersion } from "../../../utils/versionUtilFuncs";
import { fetchAndSetActiveCompanyContext } from "../../../contexts/activeCompany.contexts";
import { toast } from "react-toastify";
import capitalizeWords from "../../../utils/capitalizeWords";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

const statuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return { value: `${i}`, label: `${i}` };
});

const crmUnitOptions = [
  { label: "1 Sq foot", value: 1 },
  { label: "1000 Sq feet", value: 1000 },
  { label: "1 Acre", value: 43560 },
];

export function RealGreenIntegration() {
  const { companyId } = useParams();
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);

  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [marketingCodes, setMarketingCodes] = useState([]);
  const [callReasons, setCallReasons] = useState([]);
  const [callStatuses, setCallStatuses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [routes, setRoutes] = useState([]);

  const fetchRealGreenData = useCallback(async (rgKey) => {
    const [
      fetchedEmployees,
      fetchedMarketingCodes,
      fetchedCallReasons,
      fetchedCallStatuses,
      fetchedCompanies,
      fetchedRoutes,
    ] = await Promise.all([
      getCompanyEmployees(rgKey),
      getCompanyMarketingCodes(rgKey),
      getCompanyCallActionReasons(rgKey),
      getCompanyCallStatuses(rgKey),
      getCompanies(rgKey),
      getCompanyRoutes(rgKey),
    ]);

    return {
      employees: fetchedEmployees,
      marketingCodes: fetchedMarketingCodes,
      callReasons: fetchedCallReasons,
      callStatuses: fetchedCallStatuses,
      companies: fetchedCompanies,
      routes: fetchedRoutes,
    };
  }, []);

  const filterAndFormatMarketingCodes = useCallback(
    (codes, crmCompanyId) =>
      (codes || [])
        .map((i) => ({
          code: i.sourcecd,
          description: capitalizeWords(i.source),
          companyIds: i.companyIds || [],
        }))
        .filter((i) =>
          crmCompanyId === -1
            ? true
            : // TODO remove this string conversion here and in the API
              !i.companyIds.length || i.companyIds.includes(`${crmCompanyId}`),
        ),
    [],
  );

  const fetchCompany = useCallback(async () => {
    try {
      const activeCompany = await fetchAndSetActiveCompanyContext({
        companyId,
      });
      const rgKey = activeCompany.dataServices?.realGreenAPIKey || "";
      const crmCompanyId =
        !activeCompany.crmCompanyId || activeCompany.crmCompanyId === "null"
          ? -1
          : activeCompany.crmCompanyId;
      if (rgKey) {
        const realGreenData = await fetchRealGreenData(rgKey);
        setEmployees(realGreenData.employees);
        setCallReasons(realGreenData.callReasons);
        setCallStatuses(realGreenData.callStatuses);
        setCompanies(realGreenData.companies);
        setRoutes(realGreenData.routes);

        const defaultMarketingSources = filterAndFormatMarketingCodes(
          realGreenData.marketingCodes,
          crmCompanyId,
        );

        const storedMarketingSources =
          activeCompany?.marketingSources &&
          JSON.parse(activeCompany.marketingSources || "[]");

        const marketingSourcesToSet = (
          storedMarketingSources?.length
            ? storedMarketingSources
            : defaultMarketingSources
        ).map((i) => ({
          code: i.code,
          description: capitalizeWords(i.description),
        }));

        setMarketingCodes(defaultMarketingSources);
        setCompany({
          ...activeCompany,
          marketingSourceInquiryPrompt:
            activeCompany.marketingSourceInquiryPrompt ||
            "How did you hear about us?",
          marketingSources: marketingSourcesToSet.sort((a, b) =>
            a.description.localeCompare(b.description),
          ),
        });
        setLoaded(true);
      }
    } catch (error) {
      console.error("Error fetching company data:", error.message);
    }
  }, [companyId, fetchRealGreenData, filterAndFormatMarketingCodes]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const saveRealGreenCompanyData = async () => {
    setIsSaving(true);

    const {
      marketingSource,
      areMarketingQuestionsActivated,
      marketingSources,
      marketingSourceInquiryPrompt,
    } = company;

    if (!marketingSource) {
      toast.error("Marketing Source is required!");
      setIsSaving(false);
      return;
    }

    if (areMarketingQuestionsActivated) {
      if (!marketingSources.length) {
        toast.error("No marketing sources are added!");
        setIsSaving(false);
        return;
      }
      const marketingSourceLimit = 25;
      if (marketingSources.length > marketingSourceLimit) {
        toast.error(
          `Only ${marketingSourceLimit} marketing sources can be added!`,
        );
        setIsSaving(false);
        return;
      }
      if (!marketingSourceInquiryPrompt?.trim()) {
        toast.error("Custom verbiage for marketing questions is required!");
        setIsSaving(false);
        return;
      }
    }

    const payload = {
      ...company,
      areMarketingQuestionsActivated: !!areMarketingQuestionsActivated,
      marketingSources: JSON.stringify(marketingSources || []),
      marketingSourceInquiryPrompt: marketingSourceInquiryPrompt?.trim(),
    };

    try {
      await createNewVersion(companyId, "company", payload);
      toast.success("Settings Saved");
    } catch (error) {
      console.error("Error saving company data:", error.message);
    }
    setIsSaving(false);
  };

  const loadDefaultMarketingSourceTags = () => {
    const existingTagCodes = company.marketingSources?.map((i) => i.code) || [];
    const nonExistingTags = marketingCodes.filter(
      (source) => !existingTagCodes.includes(source.code),
    );

    if (!nonExistingTags.length) {
      toast.warn("No new sources to add!");
      return;
    }

    setCompany({
      ...company,
      marketingSources: [...company.marketingSources, ...nonExistingTags].sort(
        (a, b) => a.description.localeCompare(b.description),
      ),
    });
  };

  const handleMarketingSourceChange = async ({ value, source }) => {
    const rgKey = company.dataServices?.realGreenAPIKey;
    const fetchedMarketingCodes = await getCompanyMarketingCodes(rgKey);
    const filteredMarketingCodes = filterAndFormatMarketingCodes(
      fetchedMarketingCodes,
      value,
    );

    if (source === "crmCompanyId") {
      setMarketingCodes(filteredMarketingCodes);
      setCompany({
        ...company,
        marketingSource: null,
        [source]: value,
        marketingSources: filteredMarketingCodes.sort((a, b) =>
          a.description.localeCompare(b.description),
        ),
      });
      toast.info("Marketing sources have been updated!");
    }
  };
  return loaded ? (
    <Container className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Link color="inherit" href={`/company/${companyId}`}>
          Company
        </Link>

        <Typography color="textPrimary">RealGreen</Typography>
      </Breadcrumbs>
      <div className={classes.container}>
        <ValidatorForm onSubmit={saveRealGreenCompanyData}>
          <Title>RealGreen Settings</Title>

          <div>
            <SelectValidator
              id="companyId"
              label="Company Id"
              value={company.crmCompanyId || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Select Company"
              margin="normal"
              onChange={async (e) => {
                await handleMarketingSourceChange({
                  value: e.target.value,
                  source: "crmCompanyId",
                });
              }}
            >
              <MenuItem key={"defaultcompid"} value={-1}>
                No Company Selected
              </MenuItem>
              {companies.map((i) => {
                return (
                  <MenuItem key={`${i.comp_id}-company-id`} value={i.comp_id}>
                    {`${i.compname} ( ${i.comp_id} )`}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="employee"
              label="Lawnbot Employee"
              value={company.lawnbotEmployee || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Lawnbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, lawnbotEmployee: e.target.value });
              }}
            >
              {employees.map((i) => {
                return (
                  <MenuItem
                    key={`${i.emp_id.trim()}adfaddw`}
                    value={i.emp_id.trim()}
                  >
                    {i.name}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="route"
              label="Route"
              value={company.crmRoute || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Lawnbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
            >
              {routes.map((i) => {
                return (
                  <MenuItem
                    key={`${i.emp_id.trim()}adfaddw`}
                    value={i.route.trim()}
                  >
                    {i.route_desc}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="marketing-source"
              label="Marketing Source"
              value={company.marketingSource || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Chatbot"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, marketingSource: e.target.value });
              }}
              errorMessages={["this field is required"]}
              validators={["required"]}
            >
              {marketingCodes.map((i) => {
                return (
                  <MenuItem
                    key={`${i.code}-${i.description}`}
                    value={`${i.code}`}
                  >
                    {i.description}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <SelectValidator
            id="call-status"
            label="Sale Call Status"
            value={company.callStatusCode || null}
            style={{ margin: 8, width: 220 }}
            placeholder="Sales Call"
            margin="normal"
            onChange={(e) => {
              setCompany({ ...company, callStatusCode: e.target.value });
            }}
          >
            {callStatuses?.map((i) => {
              return (
                <MenuItem key={`${i.status}-dasd`} value={`${i.status}`}>
                  {i.statusdesc}
                </MenuItem>
              );
            })}
          </SelectValidator>

          <div>
            <SelectValidator
              id="call-action-reason"
              label="Sale Call Action Reason"
              value={company.callActionReason || null}
              style={{ margin: 8, width: 220 }}
              placeholder="Sales Call"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, callActionReason: e.target.value });
              }}
            >
              {callReasons.map((i) => {
                return (
                  <MenuItem
                    key={`${i.actreasid}-adas`}
                    value={`${i.actreasid}`}
                  >
                    {i.actreas}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>
          <div>
            <TextValidator
              id="crmPortalUrl"
              label="RealGreen Customer Portal"
              value={company.crmPortalUrl || ""}
              style={{ margin: 8, width: 400 }}
              placeholder="https://"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmPortalUrl: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmPortalUrl: e.target.value });
              }}
            />
          </div>

          <div>
            <SelectValidator
              id="newRecordStatus"
              label="New Record Status"
              value={company.crmNewRecordStatusScode || "0"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({
                  ...company,
                  crmNewRecordStatusScode: e.target.value,
                });
              }}
            >
              {statuses.map((i) => {
                const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="crmQuoteStatusCode"
              label="Quote Received Status"
              value={company.crmQuoteStatusCode || "1"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmQuoteStatusCode: e.target.value });
              }}
            >
              {statuses.map((i) => {
                const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <SelectValidator
              id="crmSaleStatusCode"
              label="Sale Made Status"
              value={company.crmSaleStatusCode || "9"}
              style={{ margin: 8, width: 220 }}
              placeholder="0"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmSaleStatusCode: e.target.value });
              }}
            >
              {statuses.map((i) => {
                const key = `menu-item-${Math.floor(Math.random() * 100000)}`;
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>

          <div>
            <TextValidator
              id="crmRoute"
              label="RealGreen Route Label"
              value={
                typeof company.crmRoute !== "undefined"
                  ? company.crmRoute
                  : "null"
              }
              style={{ margin: 8, width: 400 }}
              placeholder="XXX - Example Route"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmRoute: e.target.value });
              }}
            />
          </div>

          <div>
            <TextValidator
              id="crmDayCode"
              label="RealGreen Day Code"
              value={
                typeof company.crmDayCode !== "undefined"
                  ? company.crmDayCode
                  : ""
              }
              style={{ margin: 8, width: 400 }}
              placeholder="1"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              errorMessages={["this field is required"]}
              validators={[]}
              onChange={(e) => {
                setCompany({ ...company, crmDayCode: e.target.value });
              }}
              onBlur={(e) => {
                setCompany({ ...company, crmDayCode: e.target.value });
              }}
            />
          </div>
          <div>
            <SelectValidator
              id="crm-measurement-unit"
              label="CRM Measurement Unit"
              value={company.crmMeasurementUnit || null}
              style={{ margin: 8, width: 220 }}
              placeholder="CRM Measurement Unit"
              margin="normal"
              onChange={(e) => {
                setCompany({ ...company, crmMeasurementUnit: e.target.value });
              }}
            >
              {crmUnitOptions.map((i) => {
                const key = `menu-item-${i.value}`;
                return (
                  <MenuItem key={key} value={i.value}>
                    {i.label}
                  </MenuItem>
                );
              })}
            </SelectValidator>
          </div>
          <div style={{ padding: 15 }}>
            <FormLabel>Make Available On Mobile</FormLabel>
            <Checkbox
              id="crmHandheld"
              label="ByPass Credit Card Payment"
              checked={company.crmHandheld}
              onChange={(e) => {
                const payload = Object.assign({}, company, {
                  crmHandheld: e.target.checked,
                });
                setCompany(payload);
              }}
            />
          </div>
          <div>
            <Card
              style={{
                padding: "10px",
                marginBottom: "20px",
                paddingBottom: "20px",
              }}
            >
              <div style={{ padding: 15 }}>
                <FormLabel>Activate Marketing Questions</FormLabel>
                <Checkbox
                  id="areMarketingQuestionsActivated"
                  label="Activate Marketing Questions"
                  checked={company.areMarketingQuestionsActivated}
                  onChange={(e) => {
                    const payload = Object.assign({}, company, {
                      areMarketingQuestionsActivated: e.target.checked,
                    });
                    setCompany(payload);
                  }}
                />
              </div>
              {company?.areMarketingQuestionsActivated ? (
                <>
                  <div>
                    <TextValidator
                      id="marketingSourceInquiryPrompt"
                      label="Custom Verbiage for Marketing Questions"
                      value={company.marketingSourceInquiryPrompt}
                      style={{ margin: 8, width: 400 }}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      errorMessages={["this field is required"]}
                      validators={["required"]}
                      onChange={(e) => {
                        if (e.target.value.length > 300) {
                          return;
                        }
                        setCompany({
                          ...company,
                          marketingSourceInquiryPrompt: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 300) {
                          return;
                        }
                        setCompany({
                          ...company,
                          marketingSourceInquiryPrompt: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div>
                    <div style={{ marginTop: "20px" }}>
                      {(company.marketingSources || []).map((source) => (
                        <Chip
                          key={source.code}
                          label={source.description}
                          onDelete={() => {
                            setCompany({
                              ...company,
                              marketingSources:
                                company.marketingSources?.filter(
                                  (s) => s.code !== source.code,
                                ),
                            });
                          }}
                          style={{ margin: 4 }}
                        />
                      ))}
                    </div>

                    <div style={{ fontSize: 12, marginTop: 10 }}>
                      <em>
                        {company.marketingSources?.length || "No"} sources are
                        added.{" "}
                        {marketingCodes?.length ===
                        company.marketingSources?.length
                          ? ""
                          : `${marketingCodes?.length} sources are available from real green.`}
                      </em>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <Button
                        color="default"
                        variant="contained"
                        onClick={loadDefaultMarketingSourceTags}
                      >
                        Add Defaults
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
            </Card>
          </div>
          <Button
            disabled={isSaving}
            color="primary"
            variant="contained"
            type="submit"
          >
            {isSaving ? (
              <CircularProgress
                style={{ height: "24px", width: "24px" }}
                color="white"
              />
            ) : (
              "Save"
            )}
          </Button>
        </ValidatorForm>
      </div>
    </Container>
  ) : null;
}
