import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
const { v4: uuidv4 } = require("uuid");

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function runKeyDeletion(data, type) {
  // biome-ignore lint/complexity/noForEach: <explanation>
  keyDeletion.all.forEach((key) => {
    delete data[key];
  });
  if (type) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    keyDeletion[type].forEach((key) => {
      delete data[key];
    });
  }
}

const keyDeletion = {
  all: ["createdAt", "updatedAt", "__typename"],
  companyBranding: ["company"],
  dataServices: ["company"],
  company: [
    "branding",
    "offers",
    "dataServices",
    "bots",
    "programs",
    "priceTables",
  ],
  bot: ["company", "sections"],
  probingQuestion: ["program", "steps", "conditionals"],
  service: ["program"],
  priceTable: ["company", "rows"],
  program: ["company", "services", "probingQuestions"],
};

export const createNewVersion = async (id, type, update) => {
  const queryString = `list${capitalizeFirstLetter(type)}Versioneds`;
  try {
    const allVersions = await API.graphql(
      graphqlOperation(queries[queryString], { id, limit: 10000 }),
    );
    if (allVersions.data?.[queryString]?.items?.length) {
      const v0 = allVersions.data[queryString].items.find((item) => {
        return item.version === "v0";
      });
      const newVersion = { ...v0, ...update };
      // biome-ignore lint/complexity/noForEach: <explanation>
      keyDeletion.all.forEach((key) => {
        delete newVersion[key];
      });
      // biome-ignore lint/complexity/noForEach: <explanation>
      keyDeletion[type].forEach((key) => {
        delete newVersion[key];
      });
      newVersion.version = `v${allVersions.data[queryString].items.length}`;
      // biome-ignore lint/performance/noDelete: <explanation>
      delete newVersion.__typename;
      const newV0 = await API.graphql(
        graphqlOperation(
          mutations[`update${capitalizeFirstLetter(type)}Versioned`],
          { input: { ...newVersion, version: "v0" } },
        ),
      );
      await API.graphql(
        graphqlOperation(
          mutations[`create${capitalizeFirstLetter(type)}Versioned`],
          { input: newVersion },
        ),
      );
      return newV0;
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

export const createInitialVersions = async (type, input) => {
  try {
    const queryString = `create${capitalizeFirstLetter(type)}Versioned`;
    // biome-ignore lint/complexity/noForEach: <explanation>
    keyDeletion.all.forEach((key) => {
      delete input[key];
    });
    // biome-ignore lint/complexity/noForEach: <explanation>
    keyDeletion[type].forEach((key) => {
      delete input[key];
    });
    input.version = "v0";
    const newV0 = await API.graphql(
      graphqlOperation(mutations[queryString], { input }),
    );
    input.version = "v1";
    input.id = newV0.data[queryString].id;
    await API.graphql(graphqlOperation(mutations[queryString], { input }));
    return newV0.data[queryString];
  } catch (error) {
    console.log("error: ", error);
  }
};

export const createInitialId = (companyId) => {
  return `${companyId}_${uuidv4()}`;
};
